import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Spinner,
  Form,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import Baja from "../../Baja";
import BajaMultiple from "../../BajaMultiple";
import StatusMultiple from "../../StatusMultiple";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import qrcode from "qrcode-js";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import AutorizadoMultiple from "../../AutorizadoMultiple";

function CorteTiendas() {
  const { user } = useContext(AuthContext);
  const URL_SURTIDOS = process.env.REACT_APP_URL_SURTIDOS;
  const URL_ABONOS = process.env.REACT_APP_URL_ABONOS;
  const URL_DEVOLUCIONES = process.env.REACT_APP_URL_DEVOLUCIONES;
  const URL_WHATSAPP = process.env.REACT_APP_URL_WHATSAPP;
  const TELEFONO_SUPER_ADMIN = process.env.REACT_APP_TELEFONO_SUPER_ADMIN
  const URL_GASTOS_GENERAL = process.env.REACT_APP_URL_GASTOS_GENERAL;

  let date = new Date();
  let hoy = moment(date).format("YYYY-MM-DD");

  const [ventasTiendas, setVentasTiendas] = useState([]);
  const [abonosTiendas, setAbonosTiendas] = useState([]);
  const [devolucionesTiendas, setDevolucionesTiendas] = useState([]);
  const [selectedFechaInicio, setSelectedFechaInicio] = useState("");
  const [selectedFechaFin, setSelectedFechaFin] = useState("");
  const [gastos, setGastos] = useState([]);

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);
  const [validaBoton, setValidaBoton] = useState(false);

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");


  async function jalaInfoInicio(e) {

    if(e == "" || selectedFechaFin == ""){
      return
    }
      toggleProgreso()
    setValidaBoton(false)

    setTimeout(() => {
      setModalProgreso(false)
      setValidaBoton(true)
    }, 5000);

    //  Jalamos Info

    // Notas
    await axios
      .get(`${URL_SURTIDOS}Corte/${e}/${selectedFechaFin}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allVentas = response.data;

        // Array para pagination
        let arrayTabla = allVentas
          .sort((a, b) => (a.idSurtido > b.idSurtido ? 1 : -1))
          .map((a) => {
            return {
              _id: a._id,
              numero: a.idSurtido,
              consecutivo: a.idSurtido,
              activo: a.is_active,
              fecha: a.fecha,
              subTotal: a.subTotal,
              iva: a.iva,
              total_general: a.total_general,
              clientes: a.clientes[0].nombre_comercial,
              idClientes: a.clientes[0]._id,
              total_cantidad: a.total_cantidad,
              comisionTDC: a.comisionTDC,
              comisionFlete: a.comisionFlete,
              impuestos: a.impuestos,
              area: a.areas[0].name,
              movimiento: "Nota",
              // fiscal: a.fiscal,
              condPago: a.cond_pago,
              observaciones: a.observaciones
            };
          })
          .filter(function (el) {
            return el != null;
          });
        let data = Object.values(arrayTabla);
        setVentasTiendas(data);

        //
      })
      .catch((err) => {
        console.log(err);
      });

    // Abonos
    await axios
      .get(
        `${URL_ABONOS}AbonosVentasDiario/${e}/${selectedFechaFin}`,
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then((response) => {
        let allVentas = response.data;
        // Array para pagination
        let arrayTabla = allVentas
          .sort((a, b) => (a.idAbono > b.idAbono ? 1 : -1))
          .map((a) => {
            if(a.concepto == "Abono"){
            return {
              _id: a._id,
              numero: a.idAbono,
              fecha: a.fecha,
              fechaTransfer: a.fechaTransfer,
              clientes: a.clientes[0].nombre_comercial,
              idClientes: a.clientes[0]._id,
              total_general: a.total,
              efectivo: a.efectivo,
              tdc: a.tdc,
              transfer: a.transfer,
              movimiento: "Abono",
              condPago: a.condPago,
              concepto: a.concepto,
              destino: a.destino,
              terminal: a.terminal,
              codigoAgrupado: a.codigoAgrupado,
              observaciones: a.observaciones,
              createdAt: moment(a.createdAt).utcOffset('-0600').format("DD-MM-YYYY") + " " + moment(a.createdAt).utcOffset('-0600').format("HH:mm"),
            }}
          })
          .filter(function (el) {
            return el != null;
          });

        let data = Object.values(arrayTabla);


        let agrupado = data.reduce(function (groups, item) {
          const val = item["codigoAgrupado"]
          groups[val] = groups[val] || {
            codigoAgrupado: item.codigoAgrupado,
            createdAt: item.createdAt,
            efectivo: 0,
            tdc: 0,
            transfer: 0,
            total_general: 0
          };
          groups[val].tdc += item.tdc;
          groups[val].transfer += item.transfer;
          groups[val].efectivo += item.efectivo;
          groups[val].consecutivo = item.consecutivo;
          groups[val].clientes = item.clientes;
          groups[val].total_general += item.total_general;
          groups[val].condPago = item.condPago;
          groups[val].concepto = item.concepto;
          groups[val].terminal = item.terminal;
          groups[val].destino = item.destino;
          groups[val].fechaTransfer = item.fechaTransfer;
          groups[val].numero = item.numero;
          groups[val].observaciones = item.observaciones;
          return groups;
        }, []);

        let agrupadoValues = Object.values(agrupado);

        setAbonosTiendas(agrupadoValues);
        //
      })
      .catch((err) => {
        console.log(err);
      });

    // Devoluciones
    await axios
      .get(
        `${URL_DEVOLUCIONES}DevolucionesDiario/${e}/${selectedFechaFin}`,
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then((response) => {
        let allDevoluciones = response.data;
        // Array para pagination
        let arrayTabla = allDevoluciones
          .sort((a, b) =>
            a.idDevolucion > b.idDevolucion ? 1 : -1
          )
          .map((a) => {
            return {
              _id: a._id,
              numero: a.idDevolucion,
              activo: a.is_active,
              fecha: a.fecha,
              subTotal: a.subTotal,
              iva: a.iva,
              total_general: a.total_general,
              clientes: a.clientes[0].nombre_comercial,
              idClientes: a.clientes[0]._id,
              total_cantidad: a.total_cantidad,
              impuestos: a.impuestos,
              area: a.areas[0].name,
              movimiento: "Devolucion",
              // fiscal: a.fiscal,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let data = Object.values(arrayTabla);
        setDevolucionesTiendas(data);
        //
      })
      .catch((err) => {
        console.log(err);
      });

          // Gastos
    await axios
    .get(`${URL_GASTOS_GENERAL}GastosDiarioGeneral/${e}/${selectedFechaFin}`, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
    })
    .then((response) => {
      let allGastos = response.data;
      let arrayTabla = allGastos
        .sort((a, b) => (a.fecha < b.fecha ? 1 : -1))
        .map((a) => {
          return {
            _id: a._id,
            fecha: a.fecha,
            descripcion: a.descripcion,
            subtotal: a.subtotal,
            iva: a.iva,
            total: a.total,
            concepto: a.conceptosGastos[0].name,
            movimiento: "Gasto",
            numero: a.idGastoGeneral,
          };
        })
        .filter(function (el) {
          return el != null;
        });

      let data = Object.values(arrayTabla);
      setGastos(data);
    })
    .catch((err) => {
      console.log(err);
    });
   
  }


  async function jalaInfoFin(e) {

    if(selectedFechaInicio == "" || e == ""){
      return
    }
      toggleProgreso()
    setValidaBoton(false)

    setTimeout(() => {
      setModalProgreso(false)
      setValidaBoton(true)
    }, 5000);

    //  Jalamos Info

    // Notas
    await axios
      .get(`${URL_SURTIDOS}Corte/${selectedFechaInicio}/${e}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allVentas = response.data;

        // Array para pagination
        let arrayTabla = allVentas
          .sort((a, b) => (a.idSurtido > b.idSurtido ? 1 : -1))
          .map((a) => {
            return {
              _id: a._id,
              numero: a.idSurtido,
              consecutivo: a.idSurtido,
              activo: a.is_active,
              fecha: a.fecha,
              subTotal: a.subTotal,
              iva: a.iva,
              total_general: a.total_general,
              clientes: a.clientes[0].nombre_comercial,
              idClientes: a.clientes[0]._id,
              total_cantidad: a.total_cantidad,
              comisionTDC: a.comisionTDC,
              comisionFlete: a.comisionFlete,
              impuestos: a.impuestos,
              area: a.areas[0].name,
              movimiento: "Nota",
              // fiscal: a.fiscal,
              condPago: a.cond_pago,
              observaciones: a.observaciones
            };
          })
          .filter(function (el) {
            return el != null;
          });
        let data = Object.values(arrayTabla);
        setVentasTiendas(data);

        //
      })
      .catch((err) => {
        console.log(err);
      });

    // Abonos
    await axios
      .get(
        `${URL_ABONOS}AbonosVentasDiario/${selectedFechaInicio}/${e}`,
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then((response) => {
        let allVentas = response.data;
        // Array para pagination
        let arrayTabla = allVentas
          .sort((a, b) => (a.idAbono > b.idAbono ? 1 : -1))
          .map((a) => {
            if(a.concepto == "Abono"){
            return {
              _id: a._id,
              numero: a.idAbono,
              fecha: a.fecha,
              fechaTransfer: a.fechaTransfer,
              clientes: a.clientes[0].nombre_comercial,
              idClientes: a.clientes[0]._id,
              total_general: a.total,
              efectivo: a.efectivo,
              tdc: a.tdc,
              transfer: a.transfer,
              movimiento: "Abono",
              condPago: a.condPago,
              concepto: a.concepto,
              destino: a.destino,
              terminal: a.terminal,
              codigoAgrupado: a.codigoAgrupado,
              observaciones: a.observaciones,
              createdAt: moment(a.createdAt).utcOffset('-0600').format("DD-MM-YYYY") + " " + moment(a.createdAt).utcOffset('-0600').format("HH:mm"),
            }
            }
          })
          .filter(function (el) {
            return el != null;
          });

        let data = Object.values(arrayTabla);


        let agrupado = data.reduce(function (groups, item) {
          const val = item["codigoAgrupado"]
          groups[val] = groups[val] || {
            codigoAgrupado: item.codigoAgrupado,
            createdAt: item.createdAt,
            efectivo: 0,
            tdc: 0,
            transfer: 0,
            total_general: 0
          };
          groups[val].tdc += item.tdc;
          groups[val].transfer += item.transfer;
          groups[val].efectivo += item.efectivo;
          groups[val].consecutivo = item.consecutivo;
          groups[val].clientes = item.clientes;
          groups[val].total_general += item.total_general;
          groups[val].condPago = item.condPago;
          groups[val].concepto = item.concepto;
          groups[val].terminal = item.terminal;
          groups[val].destino = item.destino;
          groups[val].fechaTransfer = item.fechaTransfer;
          groups[val].numero = item.numero;
          groups[val].observaciones = item.observaciones;
          return groups;
        }, []);

        let agrupadoValues = Object.values(agrupado);

        setAbonosTiendas(agrupadoValues);
        //
      })
      .catch((err) => {
        console.log(err);
      });

    // Devoluciones
    await axios
      .get(
        `${URL_DEVOLUCIONES}DevolucionesDiario/${selectedFechaInicio}/${e}`,
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then((response) => {
        let allDevoluciones = response.data;
        // Array para pagination
        let arrayTabla = allDevoluciones
          .sort((a, b) =>
            a.idDevolucion > b.idDevolucion ? 1 : -1
          )
          .map((a) => {
            return {
              _id: a._id,
              numero: a.idDevolucion,
              activo: a.is_active,
              fecha: a.fecha,
              subTotal: a.subTotal,
              iva: a.iva,
              total_general: a.total_general,
              clientes: a.clientes[0].nombre_comercial,
              idClientes: a.clientes[0]._id,
              total_cantidad: a.total_cantidad,
              impuestos: a.impuestos,
              area: a.areas[0].name,
              movimiento: "Devolucion",
              // fiscal: a.fiscal,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let data = Object.values(arrayTabla);
        setDevolucionesTiendas(data);
        //
      })
      .catch((err) => {
        console.log(err);
      });

          // Gastos
    await axios
    .get(`${URL_GASTOS_GENERAL}GastosDiarioGeneral/${selectedFechaInicio}/${e}`, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
    })
    .then((response) => {
      let allGastos = response.data;
      let arrayTabla = allGastos
        .sort((a, b) => (a.fecha < b.fecha ? 1 : -1))
        .map((a) => {
          return {
            _id: a._id,
            fecha: a.fecha,
            descripcion: a.descripcion,
            subtotal: a.subtotal,
            iva: a.iva,
            total: a.total,
            concepto: a.conceptosGastos[0].name,
            movimiento: "Gasto",
            numero: a.idGastoGeneral,
          };
        })
        .filter(function (el) {
          return el != null;
        });

      let data = Object.values(arrayTabla);
      setGastos(data);
    })
    .catch((err) => {
      console.log(err);
    });
   
  } 

  
  function PDFCorte() {
    // Empezamos PDF
    // Ventas Credito
    let TotalTablaVentasCredito = 0;
    let TotalPiezasVentasCredito = 0;
    const ventasCredito = ventasTiendas.map((a) => {
      if (a.condPago == "Credito") {
        TotalTablaVentasCredito = TotalTablaVentasCredito + a.total_general;
        TotalPiezasVentasCredito =
          TotalPiezasVentasCredito + a.total_cantidad;
        return [
          a.consecutivo,
          a.clientes,
          a.total_cantidad,
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.total_general),
          a.observaciones,
        ];
      }
    });
    const ventasPDFCredito = Object.values(ventasCredito);
    const ventasPDFCreditoLimpia = ventasPDFCredito.filter(function (el) {
      return el != null;
    });

     // Ventas Contado
     let TotalTablaVentasContado = 0;
     let TotalPiezasVentasContado = 0;
     const ventasContado = ventasTiendas.map((a) => {
       if (a.condPago == "Contado") {
         TotalTablaVentasContado = TotalTablaVentasContado + a.total_general;
         TotalPiezasVentasContado =
           TotalPiezasVentasContado + a.total_cantidad;
         return [
           a.consecutivo,
           a.clientes,
           a.total_cantidad,
           new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.total_general),
           a.observaciones,
         ];
       }
     });
     const ventasPDFContado = Object.values(ventasContado);
     const ventasPDFContadoLimpia = ventasPDFContado.filter(function (el) {
       return el != null;
     });

    // Devoluciones Contado
    let TotalTablaDevoluciones = 0;
    let TotalPiezasDevoluciones = 0;
    const devoluciones = devolucionesTiendas.map((a) => {
      // if (a. == "Si") {
        TotalTablaDevoluciones =
          TotalTablaDevoluciones + a.total_general;
        TotalPiezasDevoluciones =
          TotalPiezasDevoluciones + a.total_cantidad;
        return [
          a.numero,
          a.total_cantidad,
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.total_general),
        ];
      // }
    });
    const devolucionesPDF = Object.values(devoluciones);
    const devolucionesPDFLimpia = devolucionesPDF.filter(function (
      el
    ) {
      return el != null;
    });

    // Abonos Credito
    let TotalTablaAbonosCreditoEfectivo = 0;
    let TotalTablaAbonosCreditoTDC = 0;
    let TotalTablaAbonosCreditoTransfer = 0;
    let TotalTablaAbonosCreditoTotal = 0;

    const abonosCredito = abonosTiendas.map((a) => {
      if (a.condPago == "Credito" && a.concepto == "Abono") {
        TotalTablaAbonosCreditoEfectivo =
          TotalTablaAbonosCreditoEfectivo + a.efectivo;
        TotalTablaAbonosCreditoTDC = TotalTablaAbonosCreditoTDC + a.tdc;
        TotalTablaAbonosCreditoTransfer =
          TotalTablaAbonosCreditoTransfer + a.transfer;
        TotalTablaAbonosCreditoTotal =
          TotalTablaAbonosCreditoTotal + a.total_general

        return [
          a.numero,
          a.clientes,
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.efectivo),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.tdc),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.transfer),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.total_general),
          a.observaciones,
        ];
      }
    });
    const abonosPDFCredito = Object.values(abonosCredito);
    const abonosPDFCreditoLimpia = abonosPDFCredito.filter(function (el) {
      return el != null;
    });


    // Abonos Contado
    let TotalTablaAbonosContadoEfectivo = 0;
    let TotalTablaAbonosContadoTDC = 0;
    let TotalTablaAbonosContadoTransfer = 0;
    let TotalTablaAbonosContadoTotal = 0;

    const abonosContado = abonosTiendas.map((a) => {
      if (a.condPago == "Contado" && a.concepto == "Abono") {
        TotalTablaAbonosContadoEfectivo =
          TotalTablaAbonosContadoEfectivo + a.efectivo;
        TotalTablaAbonosContadoTDC = TotalTablaAbonosContadoTDC + a.tdc;
        TotalTablaAbonosContadoTransfer =
          TotalTablaAbonosContadoTransfer + a.transfer;
        TotalTablaAbonosContadoTotal =
          TotalTablaAbonosContadoTotal + a.total_general;

        return [
          a.numero,
          a.clientes,
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.efectivo),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.tdc),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.transfer),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.total_general),
          a.observaciones,
        ];
      }
    });
    const abonosPDFContado = Object.values(abonosContado);

    const abonosPDFContadoLimpia = abonosPDFContado.filter(function (el) {
      return el != null;
    });

      // Abonos TDC
      const abonosTDC = abonosTiendas.map((a)=>{
        if(a.tdc > 0){
        return {
          importe: a.tdc,
          terminal: a.terminal
        }
      }
      })
      .filter(function (el) {
        return el != null;
      });
    
      const dataAbonosTDC = Object.values(abonosTDC);

    
      let abonosTDCAgrupado = dataAbonosTDC.reduce(function (groups, item) {
        const val = item["terminal"];
        groups[val] = groups[val] || {
          terminal: item.terminal,
          importe: 0,
        };
        groups[val].importe += item.importe;
        groups[val].terminal = item.terminal;
        return groups;
      }, []);
    
      let abonosTDCAgrupado2 = Object.values(abonosTDCAgrupado);
    


      const abnTDCPDF = abonosTDCAgrupado2.map((a)=>{
        return[
          a.terminal,
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.importe),
        ]
      })
      const abonosTDCPDF = Object.values(abnTDCPDF);
      const abonosTDCPDFLimpia = abonosTDCPDF.filter(function (
        el
      ) {
        return el != null;
      });

       // Abonos Transfer
       const abonosTransfer = abonosTiendas.map((a)=>{
        if(a.transfer > 0){
        return {
          importe: a.transfer,
          destino: a.destino
        }
      }
      })
      .filter(function (el) {
        return el != null;
      });
    
      let dataAbonosTransfer = Object.values(abonosTransfer);
 
      // Agrupado Transfer
      let abonosTransferAgrupado = dataAbonosTransfer.reduce(function (groups, item) {
        const val = item["destino"];
        groups[val] = groups[val] || {
          destino: item.destino,
          importe: 0,
        };
        groups[val].importe += item.importe;
        groups[val].destino = item.destino;
        return groups;
      }, []);
    
      let abonosTransferAgrupado2 = Object.values(abonosTransferAgrupado);
    
      // setAbonosTransferTiendas(abonosTransferAgrupado2);

      const abnTransferPDF = abonosTransferAgrupado2.map((a)=>{
        return[
          a.destino,
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.importe),
        ]
      })
      const abonosTransferPDF = Object.values(abnTransferPDF);
      const abonosTransferPDFLimpia = abonosTransferPDF.filter(function (
        el
      ) {
        return el != null;
      });

// Desgloce TDC
let TotalTablaDesgloceTDC = 0
let TotalTablaDesgloceTDCAbonos = 0
let TotalTablaDesgloceTDCDin = 0
const desgloceTDCAbonos = abonosTiendas.map((a) => {
  if(a.tdc > 0){
    TotalTablaDesgloceTDCAbonos = TotalTablaDesgloceTDCAbonos + a.tdc;
    return [
      a.numero,
      a.clientes,
      a.terminal,
      new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.tdc),
    ]
    }
})
.filter(function (el) {
  return el != null;
})
const desgloceTDCPDF = Object.values(desgloceTDCAbonos);


const desgloceTDCPDFLimpia = desgloceTDCPDF.filter(function (el) {
  return el != null;
});

// Desgloce Transfer
let TotalTablaDesgloceTransfer = 0
let TotalTablaDesgloceTransferAbonos = 0
let TotalTablaDesgloceTransferDin = 0
const desgloceTransferAbonos = abonosTiendas.map((a) => {
  if(a.transfer > 0){
    TotalTablaDesgloceTransferAbonos = TotalTablaDesgloceTransferAbonos + a.transfer;
    return [
      a.numero,
      a.clientes,
      a.destino,
      a.fechaTransfer,
      new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.transfer),
    ]
    }
})
.filter(function (el) {
  return el != null;
})
const desgloceTransferPDF = Object.values(desgloceTransferAbonos);



const desgloceTransferPDFLimpia = desgloceTransferPDF.filter(function (el) {
  return el != null;
});

    //  Gastos
    let TotalSubtotalGastos = 0;
    let TotalIvaGastos = 0;
    let TotalTotalGastos = 0;

    const arrayGastos = gastos.map((a) => {
        TotalSubtotalGastos = TotalSubtotalGastos + a.subtotal;
        TotalIvaGastos = TotalIvaGastos + a.iva;
        TotalTotalGastos = TotalTotalGastos + a.total;

        return [
          a.numero,
          a.concepto,
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.subtotal),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.iva),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.total),
        ];
    });
    const gastosPDFContado = Object.values(arrayGastos);

    const gastosPDFContadoLimpia = gastosPDFContado.filter(function (el) {
      return el != null;
    });

    // Totales
    let TotalEfectivo = TotalTablaAbonosCreditoEfectivo + TotalTablaAbonosContadoEfectivo 
    let TotalTDC = TotalTablaAbonosCreditoTDC + TotalTablaAbonosContadoTDC 
    let TotalTransfer = TotalTablaAbonosCreditoTransfer + TotalTablaAbonosContadoTransfer 
    let TotalTotal = TotalTablaAbonosCreditoTotal + TotalTablaAbonosContadoTotal 

    const doc = new jsPDF({compressPdf: true});
    var img = new Image();
    // img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    // doc.addImage(img, "png", 230, 30, 45, 15);
    // var img2 = new Image();
    // img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    // doc.addImage(img2, "png", 140, 15, 25, 25);
    doc.text(`Corte del ${selectedFechaInicio} al ${selectedFechaFin}`, 15, 10);


    // Ventas Contado
    doc.text(`Ventas Contado`, 15, 18);
    doc.autoTable({
      head: [["Numero", "Cliente", "Cantidad", "Total","Observaciones"]],
      body: ventasPDFContadoLimpia,
      startY: 20,
      foot: [
        [
          "",
          "Totales",
          new Intl.NumberFormat("en-US").format(TotalPiezasVentasContado),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TotalTablaVentasContado),
        ],
      ],
    });

    // Ventas Credito
    doc.text(`Ventas Credito`,15, doc.autoTable.previous.finalY + 10);
    doc.autoTable({
      head: [["Numero", "Cliente", "Cantidad", "Total", "Observaciones"]],
      body: ventasPDFCreditoLimpia,
      startY: doc.autoTable.previous.finalY + 13,
      foot: [
        [
          "",
          "Totales",
          new Intl.NumberFormat("en-US").format(TotalPiezasVentasCredito),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TotalTablaVentasCredito),
        ],
      ],
    });

             // Abonos Contado
             doc.text(`Abonos Contado`,15, doc.autoTable.previous.finalY + 10);
             doc.autoTable({
               head: [["Numero", "Cliente", "Efectivo", "TDC", "Transfer", "Total", "Observaciones"]],
               body: abonosPDFContadoLimpia,
               startY: doc.autoTable.previous.finalY + 13,
               foot: [
                 [
                   "",
                   "Totales",
                   new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(
                       TotalTablaAbonosContadoEfectivo
                     ),
                   new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TotalTablaAbonosContadoTDC),
                   new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(
                       TotalTablaAbonosContadoTransfer
                     ),
                   new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TotalTablaAbonosContadoTotal),
                 ],
               ],
             });

    // Abonos Credito
    doc.text(`Abonos Credito`, 15, doc.autoTable.previous.finalY + 10);
    doc.autoTable({
      head: [["Numero", "Cliente", "Efectivo", "TDC", "Transfer", "Total", "Observaciones"]],
      body: abonosPDFCreditoLimpia,
      startY: doc.autoTable.previous.finalY + 13,
      foot: [
        [
          "",
          "Totales",
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(
              TotalTablaAbonosCreditoEfectivo
            ),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TotalTablaAbonosCreditoTDC),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(
              TotalTablaAbonosCreditoTransfer
            ),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(
              TotalTablaAbonosCreditoTotal
            ),
        ],
      ],
    });




    // Devoluciones Contado
    doc.text(`Devoluciones`, 15, doc.autoTable.previous.finalY + 10);
    doc.autoTable({
      head: [["Numero", "Cantidad", "Total"]],
      body: devolucionesPDFLimpia,
      startY: doc.autoTable.previous.finalY + 13,
      foot: [
        [
          "Totales",
          new Intl.NumberFormat("en-US").format(TotalPiezasDevoluciones),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TotalTablaDevoluciones),
        ],
      ],
    });

   
    // Resumen
    doc.text(`Resumen`, 15, doc.autoTable.previous.finalY + 10);
    doc.autoTable({
      head: [
        [
          "Efectivo",
          "TDC",
          "Transfer",
          "Total Total",
          // "Efectivo - Gastos",
        ],
      ],
      body: [
        [
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TotalEfectivo),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TotalTDC),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TotalTransfer),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TotalEfectivo + TotalTDC + TotalTransfer),
        ],
      ],
      startY: doc.autoTable.previous.finalY + 13,
    });

     // Desgloce TDC
     doc.text(`Desgloce TDC`, 15, doc.autoTable.previous.finalY + 10);
     doc.autoTable({
       head: [
         [
           "Numero",
           "Cliente",
           "Terminal",
           "Importe",
         ],
       ],
       body: desgloceTDCPDFLimpia,
       startY: doc.autoTable.previous.finalY + 13,
     });

    // Resumen TDC
    doc.text(`Resumen TDC`, 15, doc.autoTable.previous.finalY + 10);
    doc.autoTable({
      head: [
        [
          "Terminal",
          "Importe",
        ],
      ],
      body: abonosTDCPDFLimpia,
      startY: doc.autoTable.previous.finalY + 13,
    });
   
     // Desgloce Transfer
     doc.text(`Desgloce Transfer`, 15, doc.autoTable.previous.finalY + 10);
     doc.autoTable({
       head: [
         [
           "Numero",
           "Cliente",
           "Destino",
           "FechaTransfer",
           "Importe",
         ],
       ],
       body: desgloceTransferPDFLimpia,
       startY: doc.autoTable.previous.finalY + 13,
     }); 

     // Resumen Transfer
     doc.text(`Resumen Transfer`, 15, doc.autoTable.previous.finalY + 10);
     doc.autoTable({
       head: [
         [
           "Destino",
           "Importe",
         ],
       ],
       body: abonosTransferPDFLimpia,
       startY: doc.autoTable.previous.finalY + 13,
     });


         // Gastos
    doc.text(`Gastos`, 15, doc.autoTable.previous.finalY + 10);
    doc.autoTable({
      head: [["Numero", "Concepto", "Subtotal", "IVA", "Total"]],
      body: gastosPDFContadoLimpia,
      startY: doc.autoTable.previous.finalY + 13,
      foot: [
        [
          "",
          "Totales",
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(
            TotalSubtotalGastos
            ),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(
            TotalIvaGastos
            ),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(
            TotalTotalGastos
            ),
        ],
      ],
    });
     doc.addPage()

    //  Resumen General
    doc.text(`Resumen General:`, 15, 20);
    doc.text(`Venta Contado: ${new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TotalTablaVentasContado)} `, 15, 30);
    doc.text(`Venta Credito: ${new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TotalTablaVentasCredito)} `, 15, 40);
    doc.text(`Cobranza Contado: ${new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TotalTablaAbonosContadoTotal)} `, 15, 50);
    doc.text(`Cobranza Credito: ${new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TotalTablaAbonosCreditoTotal)} `, 15, 60);
    doc.text(`Ventas Credito + Cobranza: ${new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TotalTablaAbonosContadoTotal + TotalTablaVentasCredito + TotalTablaAbonosCreditoTotal)} `, 15, 70);
    doc.text(`Gastos: ${new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TotalTotalGastos)} `, 15, 80);
    doc.text(`Gran Total Dinero: ${new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TotalTablaAbonosContadoTotal + TotalTablaAbonosCreditoTotal - TotalTotalGastos)} `, 15, 90);
    doc.text(`Venta Contado + Venta Credito: ${new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TotalTablaVentasContado + TotalTablaVentasCredito)} `, 15, 100);
   
    
    doc.save(`Corte-${selectedFechaInicio}.pdf`);
  }

  function PDFCorteWhatsApp() {
     // Empezamos PDF
    // Ventas Credito
    let TotalTablaVentasCredito = 0;
    let TotalPiezasVentasCredito = 0;
    const ventasCredito = ventasTiendas.map((a) => {
      if (a.condPago == "Credito") {
        TotalTablaVentasCredito = TotalTablaVentasCredito + a.total_general;
        TotalPiezasVentasCredito =
          TotalPiezasVentasCredito + a.total_cantidad;
        return [
          a.consecutivo,
          a.clientes,
          a.total_cantidad,
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.total_general),
        ];
      }
    });
    const ventasPDFCredito = Object.values(ventasCredito);
    const ventasPDFCreditoLimpia = ventasPDFCredito.filter(function (el) {
      return el != null;
    });

     // Ventas Contado
     let TotalTablaVentasContado = 0;
     let TotalPiezasVentasContado = 0;
     const ventasContado = ventasTiendas.map((a) => {
       if (a.condPago == "Contado") {
         TotalTablaVentasContado = TotalTablaVentasContado + a.total_general;
         TotalPiezasVentasContado =
           TotalPiezasVentasContado + a.total_cantidad;
         return [
           a.consecutivo,
           a.clientes,
           a.total_cantidad,
           new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.total_general),
         ];
       }
     });
     const ventasPDFContado = Object.values(ventasContado);
     const ventasPDFContadoLimpia = ventasPDFContado.filter(function (el) {
       return el != null;
     });

    // Devoluciones Contado
    let TotalTablaDevoluciones = 0;
    let TotalPiezasDevoluciones = 0;
    const devoluciones = devolucionesTiendas.map((a) => {
      // if (a. == "Si") {
        TotalTablaDevoluciones =
          TotalTablaDevoluciones + a.total_general;
        TotalPiezasDevoluciones =
          TotalPiezasDevoluciones + a.total_cantidad;
        return [
          a.numero,
          a.total_cantidad,
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.total_general),
        ];
      // }
    });
    const devolucionesPDF = Object.values(devoluciones);
    const devolucionesPDFLimpia = devolucionesPDF.filter(function (
      el
    ) {
      return el != null;
    });

    // Abonos Credito
    let TotalTablaAbonosCreditoEfectivo = 0;
    let TotalTablaAbonosCreditoTDC = 0;
    let TotalTablaAbonosCreditoTransfer = 0;
    let TotalTablaAbonosCreditoTotal = 0;

    const abonosCredito = abonosTiendas.map((a) => {
      if (a.condPago == "Credito" && a.concepto == "Abono") {
        TotalTablaAbonosCreditoEfectivo =
          TotalTablaAbonosCreditoEfectivo + a.efectivo;
        TotalTablaAbonosCreditoTDC = TotalTablaAbonosCreditoTDC + a.tdc;
        TotalTablaAbonosCreditoTransfer =
          TotalTablaAbonosCreditoTransfer + a.transfer;
        TotalTablaAbonosCreditoTotal =
          TotalTablaAbonosCreditoTotal + a.total_general

        return [
          a.numero,
          a.clientes,
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.efectivo),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.tdc),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.transfer),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.total_general),
        ];
      }
    });
    const abonosPDFCredito = Object.values(abonosCredito);
    const abonosPDFCreditoLimpia = abonosPDFCredito.filter(function (el) {
      return el != null;
    });


    // Abonos Contado
    let TotalTablaAbonosContadoEfectivo = 0;
    let TotalTablaAbonosContadoTDC = 0;
    let TotalTablaAbonosContadoTransfer = 0;
    let TotalTablaAbonosContadoTotal = 0;

    const abonosContado = abonosTiendas.map((a) => {
      if (a.condPago == "Contado" && a.concepto == "Abono") {
        TotalTablaAbonosContadoEfectivo =
          TotalTablaAbonosContadoEfectivo + a.efectivo;
        TotalTablaAbonosContadoTDC = TotalTablaAbonosContadoTDC + a.tdc;
        TotalTablaAbonosContadoTransfer =
          TotalTablaAbonosContadoTransfer + a.transfer;
        TotalTablaAbonosContadoTotal =
          TotalTablaAbonosContadoTotal + a.total_general;

        return [
          a.numero,
          a.clientes,
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.efectivo),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.tdc),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.transfer),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.total_general),
        ];
      }
    });
    const abonosPDFContado = Object.values(abonosContado);

    const abonosPDFContadoLimpia = abonosPDFContado.filter(function (el) {
      return el != null;
    });

      // Abonos TDC
      const abonosTDC = abonosTiendas.map((a)=>{
        if(a.tdc > 0){
        return {
          importe: a.tdc,
          terminal: a.terminal
        }
      }
      })
      .filter(function (el) {
        return el != null;
      });
    
      const dataAbonosTDC = Object.values(abonosTDC);

    
      let abonosTDCAgrupado = dataAbonosTDC.reduce(function (groups, item) {
        const val = item["terminal"];
        groups[val] = groups[val] || {
          terminal: item.terminal,
          importe: 0,
        };
        groups[val].importe += item.importe;
        groups[val].terminal = item.terminal;
        return groups;
      }, []);
    
      let abonosTDCAgrupado2 = Object.values(abonosTDCAgrupado);
    


      const abnTDCPDF = abonosTDCAgrupado2.map((a)=>{
        return[
          a.terminal,
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.importe),
        ]
      })
      const abonosTDCPDF = Object.values(abnTDCPDF);
      const abonosTDCPDFLimpia = abonosTDCPDF.filter(function (
        el
      ) {
        return el != null;
      });

       // Abonos Transfer
       const abonosTransfer = abonosTiendas.map((a)=>{
        if(a.transfer > 0){
        return {
          importe: a.transfer,
          destino: a.destino
        }
      }
      })
      .filter(function (el) {
        return el != null;
      });
    
      let dataAbonosTransfer = Object.values(abonosTransfer);
 
      // Agrupado Transfer
      let abonosTransferAgrupado = dataAbonosTransfer.reduce(function (groups, item) {
        const val = item["destino"];
        groups[val] = groups[val] || {
          destino: item.destino,
          importe: 0,
        };
        groups[val].importe += item.importe;
        groups[val].destino = item.destino;
        return groups;
      }, []);
    
      let abonosTransferAgrupado2 = Object.values(abonosTransferAgrupado);
    
      // setAbonosTransferTiendas(abonosTransferAgrupado2);

      const abnTransferPDF = abonosTransferAgrupado2.map((a)=>{
        return[
          a.destino,
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.importe),
        ]
      })
      const abonosTransferPDF = Object.values(abnTransferPDF);
      const abonosTransferPDFLimpia = abonosTransferPDF.filter(function (
        el
      ) {
        return el != null;
      });

// Desgloce TDC
let TotalTablaDesgloceTDC = 0
let TotalTablaDesgloceTDCAbonos = 0
let TotalTablaDesgloceTDCDin = 0
const desgloceTDCAbonos = abonosTiendas.map((a) => {
  if(a.tdc > 0){
    TotalTablaDesgloceTDCAbonos = TotalTablaDesgloceTDCAbonos + a.tdc;
    return [
      a.numero,
      a.clientes,
      a.terminal,
      new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.tdc),
    ]
    }
})
.filter(function (el) {
  return el != null;
})
const desgloceTDCPDF = Object.values(desgloceTDCAbonos);


const desgloceTDCPDFLimpia = desgloceTDCPDF.filter(function (el) {
  return el != null;
});

// Desgloce Transfer
let TotalTablaDesgloceTransfer = 0
let TotalTablaDesgloceTransferAbonos = 0
let TotalTablaDesgloceTransferDin = 0
const desgloceTransferAbonos = abonosTiendas.map((a) => {
  if(a.transfer > 0){
    TotalTablaDesgloceTransferAbonos = TotalTablaDesgloceTransferAbonos + a.transfer;
    return [
      a.numero,
      a.clientes,
      a.destino,
      a.fechaTransfer,
      new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.transfer),
    ]
    }
})
.filter(function (el) {
  return el != null;
})
const desgloceTransferPDF = Object.values(desgloceTransferAbonos);



const desgloceTransferPDFLimpia = desgloceTransferPDF.filter(function (el) {
  return el != null;
});

    // Abonos Gastos
    let TotalSubtotalGastos = 0;
    let TotalIvaGastos = 0;
    let TotalTotalGastos = 0;

    const arrayGastos = gastos.map((a) => {
        TotalSubtotalGastos = TotalSubtotalGastos + a.subtotal;
        TotalIvaGastos = TotalIvaGastos + a.iva;
        TotalTotalGastos = TotalTotalGastos + a.total;

        return [
          a.numero,
          a.concepto,
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.subtotal),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.iva),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.total),
        ];
    });
    const gastosPDFContado = Object.values(arrayGastos);

    const gastosPDFContadoLimpia = gastosPDFContado.filter(function (el) {
      return el != null;
    });

    // Totales
    let TotalEfectivo = TotalTablaAbonosCreditoEfectivo + TotalTablaAbonosContadoEfectivo 
    let TotalTDC = TotalTablaAbonosCreditoTDC + TotalTablaAbonosContadoTDC 
    let TotalTransfer = TotalTablaAbonosCreditoTransfer + TotalTablaAbonosContadoTransfer 
    let TotalTotal = TotalTablaAbonosCreditoTotal + TotalTablaAbonosContadoTotal 

    const doc = new jsPDF({compressPdf: true});
    var img = new Image();
    // img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    // doc.addImage(img, "png", 230, 30, 45, 15);
    // var img2 = new Image();
    // img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    // doc.addImage(img2, "png", 140, 15, 25, 25);
    doc.text(`Corte del ${selectedFechaInicio} al ${selectedFechaFin}`, 15, 10);


    // Ventas Contado
    doc.text(`Ventas Contado`, 15, 18);
    doc.autoTable({
      head: [["Numero", "Cliente", "Cantidad", "Total"]],
      body: ventasPDFContadoLimpia,
      startY: 20,
      foot: [
        [
          "",
          "Totales",
          new Intl.NumberFormat("en-US").format(TotalPiezasVentasContado),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TotalTablaVentasContado),
        ],
      ],
    });

    // Ventas Credito
    doc.text(`Ventas Credito`,15, doc.autoTable.previous.finalY + 10);
    doc.autoTable({
      head: [["Numero", "Cliente", "Cantidad", "Total"]],
      body: ventasPDFCreditoLimpia,
      startY: doc.autoTable.previous.finalY + 13,
      foot: [
        [
          "",
          "Totales",
          new Intl.NumberFormat("en-US").format(TotalPiezasVentasCredito),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TotalTablaVentasCredito),
        ],
      ],
    });

             // Abonos Contado
             doc.text(`Abonos Contado`,15, doc.autoTable.previous.finalY + 10);
             doc.autoTable({
               head: [["Numero", "Cliente", "Efectivo", "TDC", "Transfer", "Total"]],
               body: abonosPDFContadoLimpia,
               startY: doc.autoTable.previous.finalY + 13,
               foot: [
                 [
                   "",
                   "Totales",
                   new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(
                       TotalTablaAbonosContadoEfectivo
                     ),
                   new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TotalTablaAbonosContadoTDC),
                   new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(
                       TotalTablaAbonosContadoTransfer
                     ),
                   new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TotalTablaAbonosContadoTotal),
                 ],
               ],
             });

    // Abonos Credito
    doc.text(`Abonos Credito`, 15, doc.autoTable.previous.finalY + 10);
    doc.autoTable({
      head: [["Numero", "Cliente", "Efectivo", "TDC", "Transfer", "Total"]],
      body: abonosPDFCreditoLimpia,
      startY: doc.autoTable.previous.finalY + 13,
      foot: [
        [
          "",
          "Totales",
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(
              TotalTablaAbonosCreditoEfectivo
            ),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TotalTablaAbonosCreditoTDC),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(
              TotalTablaAbonosCreditoTransfer
            ),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(
              TotalTablaAbonosCreditoTotal
            ),
        ],
      ],
    });




    // Devoluciones Contado
    doc.text(`Devoluciones`, 15, doc.autoTable.previous.finalY + 10);
    doc.autoTable({
      head: [["Numero", "Cantidad", "Total"]],
      body: devolucionesPDFLimpia,
      startY: doc.autoTable.previous.finalY + 13,
      foot: [
        [
          "Totales",
          new Intl.NumberFormat("en-US").format(TotalPiezasDevoluciones),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TotalTablaDevoluciones),
        ],
      ],
    });

   
    // Resumen
    doc.text(`Resumen`, 15, doc.autoTable.previous.finalY + 10);
    doc.autoTable({
      head: [
        [
          "Efectivo",
          "TDC",
          "Transfer",
          "Total Total",
          // "Efectivo - Gastos",
        ],
      ],
      body: [
        [
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TotalEfectivo),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TotalTDC),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TotalTransfer),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TotalEfectivo + TotalTDC + TotalTransfer),
        ],
      ],
      startY: doc.autoTable.previous.finalY + 13,
    });

     // Desgloce TDC
     doc.text(`Desgloce TDC`, 15, doc.autoTable.previous.finalY + 10);
     doc.autoTable({
       head: [
         [
           "Numero",
           "Cliente",
           "Terminal",
           "Importe",
         ],
       ],
       body: desgloceTDCPDFLimpia,
       startY: doc.autoTable.previous.finalY + 13,
     });

    // Resumen TDC
    doc.text(`Resumen TDC`, 15, doc.autoTable.previous.finalY + 10);
    doc.autoTable({
      head: [
        [
          "Terminal",
          "Importe",
        ],
      ],
      body: abonosTDCPDFLimpia,
      startY: doc.autoTable.previous.finalY + 13,
    });
   
     // Desgloce Transfer
     doc.text(`Desgloce Transfer`, 15, doc.autoTable.previous.finalY + 10);
     doc.autoTable({
       head: [
         [
           "Numero",
           "Cliente",
           "Destino",
           "FechaTransfer",
           "Importe",
         ],
       ],
       body: desgloceTransferPDFLimpia,
       startY: doc.autoTable.previous.finalY + 13,
     }); 

     // Resumen Transfer
     doc.text(`Resumen Transfer`, 15, doc.autoTable.previous.finalY + 10);
     doc.autoTable({
       head: [
         [
           "Destino",
           "Importe",
         ],
       ],
       body: abonosTransferPDFLimpia,
       startY: doc.autoTable.previous.finalY + 13,
     });


         // Gastos
    doc.text(`Gastos`, 15, doc.autoTable.previous.finalY + 10);
    doc.autoTable({
      head: [["Numero", "Concepto", "Subtotal", "IVA", "Total"]],
      body: gastosPDFContadoLimpia,
      startY: doc.autoTable.previous.finalY + 13,
      foot: [
        [
          "",
          "Totales",
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(
            TotalSubtotalGastos
            ),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(
            TotalIvaGastos
            ),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(
            TotalTotalGastos
            ),
        ],
      ],
    });
     doc.addPage()

    //  Resumen General
    doc.text(`Resumen General:`, 15, 20);
    doc.text(`Venta Contado: ${new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TotalTablaVentasContado)} `, 15, 30);
    doc.text(`Venta Credito: ${new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TotalTablaVentasCredito)} `, 15, 40);
    doc.text(`Cobranza Contado: ${new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TotalTablaAbonosContadoTotal)} `, 15, 50);
    doc.text(`Cobranza Credito: ${new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TotalTablaAbonosCreditoTotal)} `, 15, 60);
    doc.text(`Ventas Credito + Cobranza: ${new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TotalTablaAbonosContadoTotal + TotalTablaVentasCredito + TotalTablaAbonosCreditoTotal)} `, 15, 70);
    doc.text(`Gastos: ${new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TotalTotalGastos)} `, 15, 80);
    doc.text(`Gran Total Dinero: ${new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TotalTablaAbonosContadoTotal + TotalTablaAbonosCreditoTotal - TotalTotalGastos)} `, 15, 90);
    doc.text(`Venta Contado + Venta Credito: ${new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TotalTablaVentasContado + TotalTablaVentasCredito)} `, 15, 100);
   
    // doc.save(`Corte-${selectedFechaInicio}.pdf`);
    var att = doc.output("arraybuffer");
    var base64File = encode(att);

    axios
    .post(
      `${URL_WHATSAPP}SendPDF`,
      {
        number: TELEFONO_SUPER_ADMIN,
        message:`Hola, Aquí te enviamos el Corte del Día ${selectedFechaInicio}`,
        base64File,
        fileName:`Corte-${selectedFechaInicio}.pdf`
      },
      {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem(
            "app_token"
          )}`,
        },
      }
    )
    .then(() => {
      Swal.fire("Good job!", "Enviado con exito", "success");
    })
    .catch((error) => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
      console.log(error);
    });
  }


  return (
    <>
      <Header />
      <br />
      <br />
      {user.corteTiendas ? (
        <div className="card container col-4">
          <Row>
            <Col md={8}>
              <Button
                size="sm"
                href="/MenuTiendas"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Col>
          </Row>

          <h3 align="center">Corte Diario</h3>
          <Row>
        
                <Col md={6}>
              <Label className="mr-sm-2">Fecha Inicio</Label>
              <Input
                type="date"
                value={selectedFechaInicio}
                required
                onChange={(e) => {
                  jalaInfoInicio(e.target.value);
                  setSelectedFechaInicio(e.target.value);
                }}
              />
            </Col>

            <Col md={6}>
              <Label className="mr-sm-2">Fecha Fin</Label>
              <Input
                type="date"
                value={selectedFechaFin}
                required
                onChange={(e) => {
                  jalaInfoFin(e.target.value);
                  setSelectedFechaFin(e.target.value);
                }}
              />
            </Col>
            
            </Row>
            <br />
            <Row>
            <Col sm={6}>
              {validaBoton?(
                <>
              <Button
              className="btn"
              color="danger"
              onClick={PDFCorte}
            >
             <i class="far fa-file-pdf fa-2x"></i>
            </Button>
            
            <Button
            className="btn"
            color="success"
            onClick={PDFCorteWhatsApp}
          >
            <i class="fab fa-whatsapp fa-2x"></i>
          </Button>
          </>
              ):(
                <>
                <Button
                className="btn"
                color="danger"
                disabled
              >
               <i class="far fa-file-pdf fa-2x"></i>
              </Button>
            
            <Button
            className="btn"
            color="success"
            disabled
          >
            <i class="fab fa-whatsapp fa-2x"></i>
          </Button>
              </>
              )}
            </Col>
           
          
          </Row>

      

         <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
            <ModalHeader toggle={toggleProgreso}>
              <h4>Progreso</h4>
            </ModalHeader>
            <ModalBody>
             <h4> Este proceso puede tardar varios segundos.
              <br />
              Por favor no cierre ni refresque su navegador.
              </h4>
              <br />
              <div className="progreso">
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
              </div>
            </ModalBody>
          </Modal>
        </div>
      ) : undefined}
    </>
  );
}

export default CorteTiendas;
