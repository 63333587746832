import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Spinner
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import { v4 as uuidv4 } from "uuid";

function ListadoCadenas() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;
  const URL_GRUPOS = process.env.REACT_APP_URL_GRUPOS;
  const URL_DEPARTAMENTOS_CLIENTES =
    process.env.REACT_APP_URL_DEPARTAMENTOS_CLIENTES;
  const AREAS_GENERAL = process.env.REACT_APP_AREAS_GENERAL;
  const URL_COLABORADORES = process.env.REACT_APP_URL_COLABORADORES;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const URL_AREAS = process.env.REACT_APP_URL_AREAS;
  const URL_EXPLOSION = process.env.REACT_APP_URL_EXPLOSION;
  const URL_INSUMOS = process.env.REACT_APP_URL_INSUMOS;

  const [clientes, setClientes] = useState([]);
  const [insumos, setInsumos] = useState([]);
  const [grupos, setGrupos] = useState([]);
  const [selectedGrupo, setSelectedGrupo] = useState("");
  const [departamentos, setDepartamentos] = useState([]);
  const [selectedDepartamento, setSelectedDepartamento] = useState("");
  const [colaboradores, setColaboradores] = useState([]);
  const [mailTo, setMailTo] = useState("");

  const [validaBoton, setValidaBoton] = useState(true);
  const [modalExplosion, setModalExplosion] = useState(false);
  const toggleExplosion = () => setModalExplosion(!modalExplosion);
  const [explosionExistentes, setExplosionExistentes] = useState([]);
  const [codigoExplosion, setCodigoExplosion] = useState("");
  const [idExplosion, setIdExplosion] = useState("");
  const [nombreExplosion, setNombreExplosion] = useState("");

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);

  const [inputFieldsHab, setInputFieldsHab] = useState([
    {
      id: uuidv4(),
      idHab: "",
      cantidad:0
    },
  ]);

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);


  const [activo, setActivo] = useState("");
  const [vendedor, setVendedor] = useState("");
  const [grupo, setGrupo] = useState("");

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;

  useMemo(() => {
    axios
      .get(`${URL_CLIENTES}Cadenas`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allClientes = response.data;
        // Array para pagination
        let arrayTabla = allClientes
          .sort((a, b) => (a.nombre_comercial > b.nombre_comercial ? 1 : -1))
          .map((a) => {
            return {
              _id: a._id,
              activo: a.is_active,
              telefono: a.telefono,
              email: a.email,
              calle: a.calle,
              numero_ext: a.numero_ext,
              numero_int: a.numero_int,
              pais: a.pais,
              estado: a.estado,
              ciudad: a.ciudad,
              delegacion: a.delegacion,
              colonia: a.colonia,
              codigo: a.codigo,
              cp: a.cp,
              nombre_comercial: a.nombre_comercial,
              razon_social: a.razon_social,
              RFC: a.RFC,
              grupo: a.grupos[0].name,
              idGrupo: a.grupos[0]._id,
              departamento: a.departamentosClientes[0].name,
              idDepartamento: a.departamentosClientes[0]._id,
              vendedor: a.vendedor[0].nombre + " " + a.vendedor[0].apellido,
              idVendedor: a.vendedor[0]._id,
              observaciones: a.observaciones,
              ultimo_pago: a.ultimo_pago,
              forma_pago: a.forma_pago,
              area: a.areas[0].name,
              idArea: a.areas[0]._id,
              regFiscal: a.regFiscal,
              plazo: a.plazo,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);

        setClientes(allClientes);
        setComments(dataFinal);
        //
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_GRUPOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allGrupos = response.data;
        setGrupos(allGrupos);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_COLABORADORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allColaboradores = response.data;
        setColaboradores(allColaboradores);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_DEPARTAMENTOS_CLIENTES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allDepartamentos = res.data;
        setDepartamentos(allDepartamentos);
      })
      .catch((err) => {
        console.log(err);
      });
      axios
      .get(URL_INSUMOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allInsumos = res.data;
        setInsumos(allInsumos);
      })
      .catch((err) => {
        console.log(err);
      });

  }, [user]);

  function PDFTabla() {
    const data = comments.map((a) => {
      if (
        (grupo == 0 || grupo == a.idGrupo) &&
        (vendedor == 0 || vendedor == a.idVendedor) &&
        (activo == 0 || activo == a.activo)
      ) {
        return [
          a.codigo,
          a.nombre_comercial,
          a.telefono,
          a.email,
          a.grupo,
          a.vendedor,
          a.area,
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape", "mm", "a4", true);
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 45, 15);
    doc.text(`Listado Clientes`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Codigo",
          "Nombre",
          "Telefono",
          "Mail",
          "Grupo",
          "Vendedor",
          "Almacen",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
    });
    doc.save(`Clientes.pdf`);
  }

  function excel() {
    const dataExcel = comments.map((a) => {
      if (
        (grupo == 0 || grupo == a.idGrupo) &&
        (vendedor == 0 || vendedor == a.idVendedor) &&
        (activo == 0 || activo == a.activo)
      ) {
        return {
          Codigo: a.codigo,
          Nombre: a.nombre_comercial,
          RazonSocial: a.razon_social,
          RFC: a.RFC,
          Calle: a.calle,
          NoExt: a.numero_ext,
          NoInt: a.numero_int,
          Pais: a.pais,
          Estado: a.estado,
          Ciudad: a.ciudad,
          Delegacion: a.delegacion,
          Colonia: a.colonia,
          CP: a.cp,
          Telefono: a.telefono,
          Mail: a.email,
          Vendedor: a.vendedor,
          FormaPago: a.forma_pago,
          Plazo: a.plazo,
          RegFiscal: a.regFiscal,
          Grupo: a.grupo,
          Departamento: a.departamento,
          Almacen: a.area,
          Observaciones: a.observaciones,
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ListadoCadenas";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ListadoCadenas",
        sheetFilter: [
          "Codigo",
          "Nombre",
          "RazonSocial",
          "RFC",
          "Calle",
          "NoExt",
          "NoInt",
          "Pais",
          "Estado",
          "Ciudad",
          "Delegacion",
          "Colonia",
          "CP",
          "Telefono",
          "Mail",
          "Vendedor",
          "FormaPago",
          "Plazo",
          "RegFiscal",
          "Grupo",
          "Departamento",
          "Almacen",
          "Observaciones",
        ],
        sheetHeader: [
          "Codigo",
          "Nombre",
          "RazonSocial",
          "RFC",
          "Calle",
          "NoExt",
          "NoInt",
          "Pais",
          "Estado",
          "Ciudad",
          "Delegacion",
          "Colonia",
          "CP",
          "Telefono",
          "Mail",
          "Vendedor",
          "FormaPago",
          "Plazo",
          "RegFiscal",
          "Grupo",
          "Departamento",
          "Almacen",
          "Observaciones",
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    const data = comments.map((a) => {
      if (
        (grupo == 0 || grupo == a.idGrupo) &&
        (vendedor == 0 || vendedor == a.idVendedor) &&
        (activo == 0 || activo == a.activo)
      ) {
        return [
          a.codigo,
          a.nombre_comercial,
          a.telefono,
          a.email,
          a.grupo,
          a.vendedor,
          a.area,
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape", "mm", "a4", true);
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 45, 15);
    doc.text(`Listado Clientes`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Codigo",
          "Nombre",
          "Telefono",
          "Mail",
          "Grupo",
          "Vendedor",
          "Almacen",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
    });
    var att = doc.output("arraybuffer");
    var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Listado Clientes",
          email: mailTo,
          fileName: "ListadoCadenas.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el listado de Clientes.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire("Good job!", "Enviado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  const headers = [
    { name: "Codigo", field: "codigo", sortable: true },
    { name: "Nombre Comercial", field: "nombre_comercial", sortable: true },
    { name: "Telefono", field: "telefono", sortable: false },
    { name: "Email", field: "email", sortable: true },
    { name: "Almacen", field: "area", sortable: true },
    { name: "Grupo", field: "grupo", sortable: true },
    { name: "Departamento", field: "departamento", sortable: true },
    { name: "Vendedor", field: "vendedor", sortable: true },
    { name: "Acciones", field: "acciones", sortable: false },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.nombre_comercial
            .toLowerCase()
            .includes(search.toLowerCase()) ||
          comment.departamento.toLowerCase().includes(search.toLowerCase()) ||
          comment.email.toLowerCase().includes(search.toLowerCase()) ||
          comment.grupo.toLowerCase().includes(search.toLowerCase()) ||
          comment.codigo.toLowerCase().includes(search.toLowerCase()) ||
          comment.vendedor.toLowerCase().includes(search.toLowerCase()) ||
          comment.area.toLowerCase().includes(search.toLowerCase())
      );
    }
    if (grupo) {
      computedComments = computedComments.filter((e) =>
        e.idGrupo.includes(grupo)
      );
    }
    if (vendedor) {
      computedComments = computedComments.filter((e) =>
        e.idVendedor.includes(vendedor)
      );
    }
    if (selectedDepartamento) {
      computedComments = computedComments.filter((e) =>
        e.idDepartamento.includes(selectedDepartamento)
      );
    }
    if (activo) {
      computedComments = computedComments.filter((e) =>
        e.activo.includes(activo)
      );
    }


    setTotalItems(computedComments.length);

    //Sorting comments
    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    vendedor,
    grupo,
    activo,
    selectedDepartamento,
  ]);

  function explosion(id, codigo, nombre){
    setIdExplosion(id)
    setCodigoExplosion(codigo)
    setNombreExplosion(nombre)

    axios
    .get(`${URL_EXPLOSION}Cliente/${id}`, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
    })
    .then((res) => {
      const allExplosionExistentes = res.data;
      setExplosionExistentes(allExplosionExistentes);
    })
    .catch((err) => {
      console.log(err);
    });

    toggleExplosion()

  }

  const handleRemoveFieldsHab = (id) => {
    const values = [...inputFieldsHab];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFieldsHab(values);
  };

  const handleChangeInputExistente = (id, event) => {
    const newExplosionExistentes = explosionExistentes.map((i) => {
      if (id === i._id) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });
    setExplosionExistentes(newExplosionExistentes);
  };

  const handleAddFieldsHab = () => {
    setInputFieldsHab([
      ...inputFieldsHab,
      {
        id: uuidv4(),
        idHab: "",
        cantidad:0
      },
    ]);
  };

  const handleChangeInputHab = (id, event) => {
    const newInputFieldsHab = inputFieldsHab.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });
    setInputFieldsHab(newInputFieldsHab);
  };

function saveExplosion(){
  setValidaBoton(false);
  toggleProgreso();

let totalExplosion = inputFieldsHab.length + explosionExistentes.length


  inputFieldsHab.map((a)=>{
    if(a.idHab!="" & a.cantidad > 0){
    axios.post(
      URL_EXPLOSION,
      {
        clientes: idExplosion,
        insumos: a.idHab,
        cantidad: a.cantidad,
      },
      {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      }
    ).then(()=>{
      totalExplosion = totalExplosion -1
      if(totalExplosion == 0 ){
        axios.post(
          URL_LOGS,
          {
            tipo: "Agregar Explosion",
            detalle: `${idExplosion} ${a.idHab}`,
            user: user.id,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
          .then(()=>{
            Swal.fire("Good job!", "Creado con exito", `success`);
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          })
      }
    })
  }else{
    totalExplosion = totalExplosion -1
  }
  })

  explosionExistentes.map((a)=>{
    axios.patch(
      `${URL_EXPLOSION}/${a._id}`,
      {
        cantidad: a.cantidad,
      },
      {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      }
    ).then(()=>{
      totalExplosion = totalExplosion -1
      if(totalExplosion == 0 ){
        axios.post(
          URL_LOGS,
          {
            tipo: "Editar Explosion",
            detalle: `${idExplosion}`,
            user: user.id,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
          .then(()=>{
            Swal.fire("Good job!", "Creado con exito", `success`);
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          })
      }
    })
  })

}

  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_cadenas ? (
        <div className="card col-12">
          <>
            <Row>
              <Col md={8}>
              </Col>
              <Col sm={4}>
                <ButtonGroup id="logoutBoton">
                  <Button
                    size="sm"
                    className="btn"
                    color="danger"
                    onClick={PDFTabla}
                  >
                    PDF <i class="far fa-file-pdf"></i>
                  </Button>
                  <Button
                    size="sm"
                    className="btn"
                    onClick={toggleMail}
                    color="info"
                  >
                    eMail <i class="fas fa-at"></i>
                  </Button>
                  <Button
                    size="sm"
                    className="btn"
                    color="primary"
                    onClick={excel}
                  >
                    Excel <i class="far fa-file-excel"></i>
                  </Button>
                </ButtonGroup>
              </Col>
            </Row>
            <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
              <ModalHeader toggle={toggleMail}>
                <h4>Enviar Listado Cadenas</h4>
              </ModalHeader>
              <ModalBody>
                <Label className="mr-sm-2">Email</Label>
                <Input
                  className="col-sm-12"
                  type="text"
                  value={mailTo}
                  required
                  onChange={(e) => {
                    setMailTo(e.target.value);
                  }}
                />
              </ModalBody>
              <ModalFooter>
                <Button size="sm" color="success" onClick={enviaMail}>
                  Enviar
                </Button>
              </ModalFooter>
            </Modal>
            <h3 align="center">Cadenas</h3>
            <div className="row">
              <div className="col-md-8">
                <Pagination
                  total={totalItems}
                  itemsPerPage={ITEMS_PER_PAGE}
                  currentPage={currentPage}
                  onPageChange={(page) => setCurrentPage(page)}
                />
              </div>
              <div className="col-md-4 d-flex flex-row-reverse">
                <Search
                  onSearch={(value) => {
                    setSearch(value);
                    setCurrentPage(1);
                  }}
                />
              </div>
            </div>
            <Row>
              <Col md={2}>
                <Label>Grupos</Label>
                <Input
                  bsSize="sm"
                  type="select"
                  value={grupo}
                  onChange={(e) => {
                    setGrupo(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona</option>
                  {grupos
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.name}</option>;
                    })}
                </Input>
              </Col>
              {user.departamentos == AREAS_GENERAL ? (
                <Col md={2}>
                  <Label>Departamento</Label>
                  <Input
                    bsSize="sm"
                    type="select"
                    value={selectedDepartamento}
                    onChange={(e) => {
                      setSelectedDepartamento(e.target.value);
                      setCurrentPage(1);
                    }}
                  >
                    <option value="">Selecciona</option>
                    {departamentos
                      .sort((a, b) => (a.name > b.name ? 1 : -1))
                      .map((a) => {
                        return <option value={a._id}>{a.name}</option>;
                      })}
                  </Input>
                </Col>
              ) : undefined}

              <Col md={2}>
                <Label>Vendedor</Label>
                <Input
                  bsSize="sm"
                  type="select"
                  value={vendedor}
                  onChange={(e) => {
                    setVendedor(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="0">Selecciona</option>
                  {colaboradores
                    .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                    .map((a) => {
                      // if (a.cobrador == "Si") {
                      return (
                        <option value={a._id}>
                          {a.nombre} {a.apellido}
                        </option>
                      );
                      // }
                    })}
                </Input>
              </Col>
              <Col md={2}>
                <Label>Activo</Label>
                <Input
                  bsSize="sm"
                  type="select"
                  value={activo}
                  required
                  onChange={(e) => {
                    setActivo(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona</option>
                  <option value="Si">Activos</option>
                  <option value="No">Inactivos</option>
                </Input>
              </Col>
            </Row>
            <br />
            <Table size="sm" striped borderless className="table-responsive-xl">
              <TableHeader
                headers={headers}
                onSorting={(field, order) => setSorting({ field, order })}
              />
              <tbody>
                {commentsData.map((p) => {
                  {
                    return (
                      <tr>
                        <td>{p.codigo}</td>
                        <td> {p.nombre_comercial}</td>
                        <td>{p.telefono}</td>
                        <td>{p.email}</td>
                        <td>{p.area}</td>
                        <td>{p.grupo}</td>
                        <td>{p.departamento}</td>
                        <td>{p.vendedor}</td>
                        <td> <Button
                              color="success"
                              id="Editar"
                              size="sm"
                              onClick={(e) =>
                                explosion(
                                  p._id,
                                  p.codigo,
                                  p.nombre_comercial
                                )
                              }
                            >
                              <i class="fas fa-tag"></i>
                            </Button></td>
                      </tr>
                    );
                  }
                })}
              </tbody>
            </Table>
            <div className="col-md-6">
              <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
          </>

          <Modal size="sm" isOpen={modalExplosion} toggle={toggleExplosion}>
        <ModalHeader toggle={toggleExplosion}>
          <h4>
            Explosion Cliente {codigoExplosion} / {nombreExplosion}
          </h4>
        </ModalHeader>
        <ModalBody>

        
        <Row>
            <Col md={6}>
            <Label>Insumos</Label>
            </Col>
            <Col md={3}>
            <Label>Cantidad</Label>
            </Col>
          </Row>
          {explosionExistentes.map((a)=>(
            <div key={a._id}>
              <Row>
              <Col md={6}>
                      <Input
                        bsSize="sm"
                        name="insumos"
                        type="select"
                        value={a.insumos[0]._id}
                        required
                        disabled
                      >
                        <option value="">Selecciona</option>
                        {insumos
                          .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                          .map((a) => {
                            return (
                              <option value={a._id}>
                                 {a.nombre}
                              </option>
                            );
                          })}
                      </Input>
                    </Col>
                    <Col md={3}>
                        <Input
                         bsSize="sm"
                          name="cantidad"
                          type="number"
                          value={a.cantidad}
                          onChange={(event)=>{handleChangeInputExistente(a._id, event)}}
                        >
                        </Input>
                      </Col>
              </Row>
            </div>
          ))}
          {inputFieldsHab.map((a)=>(
            <div key={a.id}>
              <Row>
              <Col md={6}>
                      <Input
                        bsSize="sm"
                        name="idHab"
                        type="select"
                        value={a.idHab}
                        required
                        onChange={(event)=>{handleChangeInputHab(a.id, event)}}
                      >
                        <option value="">Selecciona</option>
                        {insumos
                          .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                          .map((a) => {
                            return (
                              <option value={a._id}>
                                {a.nombre}
                              </option>
                            );
                          })}
                      </Input>
                    </Col>
                    <Col md={3}>
                        <Input
                         bsSize="sm"
                          name="cantidad"
                          type="number"
                          value={a.cantidad}
                          onChange={(event)=>{handleChangeInputHab(a.id, event)}}
                        >
                        </Input>
                      </Col>
                      <Col>
                      <Button
                        size="sm"
                        className="btn"
                        color="danger"
                        disabled={inputFieldsHab.length === 1}
                        onClick={() => handleRemoveFieldsHab(a.id)}
                        tabindex="-1"
                      >
                        <i class="fas fa-minus"></i>
                      </Button>
                      <Button 
                      size="sm"
                      className="btn"
                      color="info"
                      onClick={handleAddFieldsHab}
                      tabindex="-1">
                      <i class="fas fa-plus"></i>
                      </Button>
                    </Col>
              </Row>
            </div>
          ))}
      </ModalBody>
        <ModalFooter>
        {validaBoton ? (
              <Button type="submit" className="btn btn-success" onClick={saveExplosion}>
                Guardar
              </Button>
            ) : (
              <Button type="submit" className="btn btn-success" disabled>
                Guardar
              </Button>
            )}
        </ModalFooter>
      </Modal>

      <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
            <ModalHeader toggle={toggleProgreso}>
              <h4>Progreso</h4>
            </ModalHeader>
            <ModalBody>
             <h4> Este proceso puede tardar varios minutos.
              <br />
              Por favor no cierre ni refresque su navegador.
              </h4>
              <br />
              <div className="progreso">
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
              </div>
            </ModalBody>
          </Modal>

        </div>
      ) : undefined}
      <br />

      {loader}
    </>
  );
}

export default ListadoCadenas;
